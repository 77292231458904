import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import "./Jeu.css?vers=1.6.0";

const Jeu = ({ data, letterSelected, end }) => {
  const [word, setWord] = useState([]);

  useEffect(() => {
    setWord(data);
  }, [data]);

  return word ? (
    <div className={end ? "rootJeu endJeu" : "rootJeu"}>
      {word.map((item, key) =>
        letterSelected.includes(word[key]) ? (
          <div
            className="letterFind"
            style={{ color: end && "goldenrod" }}
            key={key}
          >
            {item}
          </div>
        ) : (
          <div className="letterToFind" key={key}>
            __
          </div>
        )
      )}
    </div>
  ) : (
    "Génération de mots en cours [...]"
  );
};

export default Jeu;
