import React from "react";
import "./Letters.css?vers=1.6.0";

const Letters = ({ data, onChangeLetterSelected, letterSelected, end }) => {
  function handleCheckLetter(letter) {
    letter = letter.toUpperCase();
    if (!letterSelected.includes(letter)) {
      onChangeLetterSelected(letter);
    }
    return;
  }

  return (
    <div className="globalRootLetters">
      <div className="rootLetters">
        {data.map((item, key) =>
          !letterSelected.includes(item.toUpperCase()) ? (
            end ? (
              <div className="lettersDisabled" key={key}>
                {item.toUpperCase()}
              </div>
            ) : (
              <div
                className="letters"
                key={key}
                onClick={() => handleCheckLetter(item)}
              >
                {item.toUpperCase()}
              </div>
            )
          ) : (
            <div className="lettersDisabled" key={key}>
              {item.toUpperCase()}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Letters;
