import React from "react";
import "./Home.css?vers=1.6.0";
import letters from "../../mocks/letters.json";
import words from "../../mocks/words.json";
import Title from "../../components/atoms/title/Title";
import Jeu from "../../components/atoms/jeu/Jeu";
import Letters from "../../components/atoms/letters/Letters";
import End from "../../components/atoms/end/End";
import { useState } from "react";
import { useEffect } from "react";

const Home = () => {
  const [letterSelected, setLetterSelected] = useState([]);

  function onChangeLetterSelected(letter) {
    setVisibleEnd(true);
    setLetterSelected([...letterSelected, letter]);
  }

  let random = Math.floor(Math.random() * words.length);
  const [randNumber, setRandomNumber] = useState(random);

  function createWordAndSplit(data) {
    let randomWord = data[randNumber].toUpperCase();
    let randomWordSplit = randomWord.split("");
    return randomWordSplit;
  }

  function resetGame() {
    setLetterSelected([]);
    setRandomNumber(random);
  }

  useEffect(() => {
    resetGame();
  }, []);

  let compareArray = letterSelected.filter((e) =>
    createWordAndSplit(words).includes(e)
  ).length;

  let end = compareArray === [...new Set(createWordAndSplit(words))].length;

  const [visibleEnd, setVisibleEnd] = useState(true);
  function continueGame() {
    setVisibleEnd(false);
  }

  return (
    <>
      {visibleEnd && (end || letterSelected.length === 10) && (
        <End
          nbTenta={letterSelected.length}
          reset={resetGame}
          word={createWordAndSplit(words)}
          win={end}
          continueGame={continueGame}
        />
      )}

      <div className="root">
        <div className="topApp">
          <Title name="Jeu du pendu" />
          <div className="rootTitleReset">
            <div className="btnReset" onClick={resetGame}>
              Nouvelle partie
            </div>
          </div>

          <div className="nbTenta">
            {letterSelected.length <= 1
              ? "Lettre utilisée : "
              : "Lettres utilisées : "}
            {letterSelected.length}
          </div>
        </div>
        <Jeu
          data={createWordAndSplit(words)}
          letterSelected={letterSelected}
          end={end}
        />
        <Letters
          data={letters}
          onChangeLetterSelected={onChangeLetterSelected}
          letterSelected={letterSelected}
          end={end}
        />
      </div>
    </>
  );
};

export default Home;
