import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import "./End.css?vers=1.6.0";

const End = ({ nbTenta, reset, word, win, continueGame }) => {
  return (
    <div className="rootEnd">
      <div className="screen">
        <div className="close" onClick={continueGame}>
          <FontAwesomeIcon icon={faCircleXmark} />
        </div>
        {win ? (
          <>
            <div className="felications">Félications ! Partie terminée </div>
            <div className="motTrouve">{word}</div>
          </>
        ) : (
          <div className="perdu">Perdu </div>
        )}
        <div className="tenta">{nbTenta} Lettres utilisées</div>
        <div className="ratio">
          Ratio ( Nombre de Lettres unique dans le mot / Nombre de Lettres
          utilisées ) :{" "}
          <span
            style={{
              fontSize: "20px",
              color:
                Math.floor(([...new Set(word)].length / nbTenta) * 100) > 40
                  ? "green"
                  : "red",
              textDecoration: "underline",
            }}
          >
            {Math.floor(([...new Set(word)].length / nbTenta) * 100)}%
          </span>
        </div>

        {win ? (
          <div style={{ width: "50%" }} className="btn btn2" onClick={reset}>
            Recommencer une partie
          </div>
        ) : (
          <div className="choice">
            <div
              style={{ width: "50%", background: "red" }}
              className="btn btn2"
              onClick={reset}
            >
              Recommencer une partie
            </div>
            <div
              style={{ width: "50%", background: "green" }}
              className="btn btn2"
              onClick={continueGame}
            >
              Continuer la partie
            </div>
          </div>
        )}
      </div>
      <div className="end" onClick={continueGame}></div>
    </div>
  );
};

export default End;
